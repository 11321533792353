// Component: UI Loader
// ========================================================================

// ** Base Styles Imports
// @import '../../scss/base/bootstrap-extended/include';
// @import '../../scss/base/components/include';

.ui-loader {
  position: relative;
}

.ui-loader.show .overlay {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3); /* Assuming $black is black */
}

.ui-loader.show > :first-child,
.ui-loader.show code {
  background-color: initial;
}

.ui-loader .loader {
  top: 50%;
  left: 50%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: translate(-50%, -50%);
}
.text-primary {
  --bs-text-opacity: 1 !important;
  color: #213A7D !important;
}
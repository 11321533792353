body {
  font-family: "Inter";
}

button.button-with-icon {
  background: #034da3;
  border-radius: 4px;
  padding: 6px 8px 6px 8px;
  font-weight: 500;
  font-family: "Inter";
}
button.button-with-icon:hover {
  background: #125fb9 !important;
  border-radius: 4px !important;
}

button.button-with-icon:active {
  background: #125fb9 !important;
  border-radius: 4px !important;
}
button.button-with-icon:disabled {
  background: #63a4ff;
  border-radius: 4px;
}

button.button-without-icon {
  background: #034da3;
  border-radius: 4px;
}
button.button-without-icon:hover {
  background: #125fb9;
  border-radius: 4px;
  cursor: pointer;
}
button.button-without-icon:active {
  background: #5469d4;
  border-radius: 4px;
}
button.button-without-icon:disabled {
  background: #63a4ff;
  border-radius: 4px;
}

button.outlined-button {
  border: 1px solid #c2c7cf;
  padding: 6px 12px;
  color: #3c4257;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
    0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

button.outlined-button:hover {
  background: #e3e8ee;
  border: 1px solid #c2c7cf;
  border-radius: 4px;
  color: #3c4257;
}

button.outlined-button:active {
  background: #e3e8ee;
  border: 1px solid #c2c7cf;
  border-radius: 4px;
  color: #3c4257;
}

button.outlined-button:disabled {
  background: #ffffff;
  border: 1px solid #c2c7cf;
  border-radius: 4px;
  color: #a3acb9;
}

button.outlined-button-with-icon {
  border: 1px solid #c2c7cf;
  padding: 6px 8px 6px 8px;
  color: #3c4257;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
    0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-weight: 500;
}

button.outlined-button-with-icon:hover {
  background: #e3e8ee !important;
  border: 1px solid #c2c7cf !important ;
  border-radius: 4px !important ;
  color: black !important ;
}

button.outlined-button-with-icon:active {
  background: #e3e8ee !important;
  border: 1px solid #c2c7cf !important;
  border-radius: 4px !important;
  color: black !important;
}

button.outlined-button-with-icon:disabled {
  background: #ffffff;
  border: 1px solid #c2c7cf;
  border-radius: 4px;
  color: #a3acb9;
}

td > span.warning-style {
  background-color: yellow;
  color: #212529;
  border-radius: 5px;
  font-size: 12px;
  padding-top: 0.5px;
  padding-bottom: 3px;
  padding-right: 7px;
  padding-left: 10px;
  font-weight: 500;
}
td > span.success-style {
  background-color: #cbf4c9;
  color: #0e6245;
  border-radius: 5px;
  font-size: 12px;
  padding-top: 0.5px;
  padding-bottom: 3px;
  padding-right: 7px;
  padding-left: 10px;
  font-weight: 500;
}
td > span.danger-style {
}
.page-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #1a1f36;
}
/* ::placeholder {
  color: #a3acb9;
  font-size: 14px;
} */

body {
  overflow-x: hidden;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
  /* border: none; */
  transition: none;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
  border: none;
  transition: none;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:active {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
  border: none;
  transition: none;
}

.nav-tabs {
  transition: none !important;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:not(hover) {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
  border: none;
  transition: none;
}
.MuiButtonBase-root:active {
  background-color: red;
}

/* .was-validated .form-control:valid,
.form-control.is-valid {
  border-color: var(--bs-form-valid-border-color) !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.5em + 0.375rem) calc(0.75em + 0.375rem) !important;
} */
.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
  background-color: white;
  width: 420px !important;
  max-height: 220px;
  overflow-y: scroll;
  border-radius: 7px;
}
.form-control:hover {
  /* Change the color to black */
  /* border-color: black !important; */
}
.react-tel-input .form-control:focus {
  border-color: #000 !important;
  box-shadow: 0 0 0 0.5px #000 !important;
}

.react-tel-input .form-control {
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  width: 100% !important;
  outline: none;
  padding: 18.5px 14px 18.5px 58px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
}

.react-tel-input .country-list .search-box {
  border: 1px solid #cacaca;
  border-radius: 3px;
  font-size: 15px;
  line-height: 15px;
  margin-left: 6px;
  padding: 3px 8px 5px;
  outline: none;
  width: 380px !important;
  margin-top: 40px !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: #f1f1f1;
  margin-top: -10%;
}

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #034da3 !important;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.active .nav-link .active {
  padding-right: 0%;
}

/* .form-select {
  --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22" fill="none"%3e%3cpath d="M10.9999 12.0743L15.5374 7.53676L16.8336 8.83292L10.9999 14.6666L5.16626 8.83292L6.46243 7.53676L10.9999 12.0743Z" fill="%232f2b3d" fill-opacity="0.9"/%3e%3c/svg%3e);
  display: block;
  width: 100%;
  padding: .426rem 2.625rem .426rem .9375rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.625;
  color: #444050 !important;
  appearance: none;
  background-color: rgba(0, 0, 0, 0) !important;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right .9375rem center;
  background-size: 22px 24px;
  border: var(--bs-border-width) solid #d1d0d4 !important;
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
} */
input {
  background: #ffffff;
  border: 1px solid #d9dce1;
  border-radius: 5px;
  color: "#A3ACB9";
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  height: 44px;
  padding-left: 0.5rem;
}

.select__indicator-separator .css-3jktoc-indicatorSeparator {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  width: 1px;
  background-color: #ededed;
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
  display: none !important;
}

/* input .form-control :hover {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */

.text-primary {
  --bs-text-opacity: 1 !important;
  color: #213a7d !important;
}

@media (min-width: 1350px) {
  .dropzone-container {
    padding: 20px;
    border: 2px dashed #3d66d2;
    border-radius: 10px;
    height: 10rem;
  }
}
@media (max-width: 1351px) {
  .dropzone-container {
    padding: 20px;
    border: 2px dashed #3d66d2;
    border-radius: 10px;
    height: 11rem;
  }
}

.dropzone-container-error {
  padding: 20px;
  border: 2px dashed red;
  border-radius: 10px;
  height: 190px;
}

.dropzone-container:hover {
  background-color: #e9eff8;
  cursor: pointer;
}
.dropzone-container-error:hover {
  background-color: #e9eff8;
  cursor: pointer;
}
.dropzone-loader {
  background: #cee6ff;
  border-radius: 5px;
}
.upload-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height, or 125% */

  align-items: center;
  text-align: center;

  /* Primary */

  color: #213a7d;
}

.upload-format {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height, or 125% */

  align-items: center;
  text-align: center;

  /* Secondary */

  color: #3d66d2;
}

.progress-bar {
  background-color: #034da3;
  color: #034da3;
}
